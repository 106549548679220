import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
//import MainLayout from './layouts/MainLayout';
import NotFoundView from './views/errors/NotFoundView';
import StockView from './views/gpu';
import ConsoleView from './views/console';
import SuggestView from './views/suggest';
import DropsView from './views/drops';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'stock', element: <StockView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      //{ path: 'login', element: <LoginView /> },
      { path: '404', element: <NotFoundView /> },
      { path: 'gpu', element: <StockView /> },
      // { path: 'all', element: <ConsoleView /> },
      // { path: 'ps5', element: <ConsoleView /> },
      // { path: 'xbox', element: <ConsoleView /> },
      // { path: 'amdcard', element: <ConsoleView /> },
      // { path: 'amdprocessor', element: <ConsoleView /> },
      // { path: 'nvidiacards', element: <ConsoleView /> },
      // { path: 'suggest', element: <SuggestView /> },
      // { path: 'drops', element: <DropsView /> },
      { path: '/', element: <Navigate to="/gpu" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
