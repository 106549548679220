import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SuggestView = () => {
  const classes = useStyles();
  const [sent, setSent] = useState(false);

  async function sendProduct(name, url) {
    let sent = false;
    var host = 'https://api.stocknotificate.com:8000';
    if (process.env.NODE_ENV !== 'production') {
      host = 'https://localhost:8000';
    }
    await fetch(host + '/suggestion', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ name: name, url: url })
    })
      .then(res => res.json())
      .then(
        result => {
          console.log(result);
          sent = true;
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => {}
      );
    return sent;
  }

  async function submitForm(event) {
    await sendProduct(event.product, event.url);
    setSent(true);
  }

  return (
    <Page className={classes.root} title="New product">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          {sent ? (
            'Thank you for suggestion, we well get to it shortly.'
          ) : (
            <Formik
              initialValues={{
                product: '',
                url: ''
              }}
              validationSchema={Yup.object().shape({
                product: Yup.string()
                  .max(255)
                  .required('Product is required'),
                url: Yup.string()
                  .max(255)
                  .required('URL is required')
              })}
              onSubmit={submitForm}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography color="textPrimary" variant="h2">
                      New product
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Suggest new product for stock monitoring
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.product && errors.product)}
                    fullWidth
                    helperText={touched.product && errors.product}
                    label="Product"
                    margin="normal"
                    name="product"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.product}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.url && errors.url)}
                    fullWidth
                    helperText={touched.url && errors.url}
                    label="Product URL"
                    margin="normal"
                    name="url"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.url}
                    variant="outlined"
                  />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Send
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          )}
        </Container>
      </Box>
    </Page>
  );
};

export default SuggestView;
