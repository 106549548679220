import React from 'react';
import { Outlet } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
});

class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MobileNavOpen: false
    };
  }

  render() {
    const { classes } = this.props;

    /*if (get_token() == null) {
      return <Navigate to={'/login'} replace={true} />;
    }*/

    return (
      <div className={classes.root}>
        <TopBar
          onMobileNavOpen={() => this.setState({ MobileNavOpen: true })}
        />
        <NavBar
          onMobileClose={() => this.setState({ MobileNavOpen: false })}
          openMobile={this.state.MobileNavOpen}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(DashboardLayout);
