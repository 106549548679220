import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';
import GlobalStyles from './components/GlobalStyles';
// import 'src/mixins/chartjs';
import theme from './theme';
import routes from './routes';

const App = () => {
  const routing = useRoutes(routes);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
      <CookieConsent style={{ 'z-index': '1500' }}>
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </ThemeProvider>
  );
};

export default App;
