import React, { lazy, Suspense } from 'react';
import { Box, Container } from '@material-ui/core';
import fetchWithTimeout from './../../utils/Fetch';
const Page = lazy(() => import('./../../components/Page'));
const Drops = lazy(() => import('./drops'));

class DropsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      data: [],
      interval: 0,
      param: ''
    };
  }

  getProduct(id) {
    for (var i = 0; i < this.state?.products.length; i++) {
      let product = this.state?.products[i];
      if (product.id === id) {
        return product;
      }
    }
  }

  requestProducts = () => {
    var host = 'https://api.stocknotificate.com:8000/latest';
    /*if (process.env.NODE_ENV !== 'production') {
      host = 'https://localhost:8000/latest';
    }*/
    fetchWithTimeout(host, {
      timeout: 3000,
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    })
      .then(res => res.json())
      .then(
        result => {
          console.log(result);
          // Update listing
          this.setState({
            products: result.filter(row => row.drops > 0)
          });
        },
        error => {
          this.setState({
            error
          });
        }
      );
  };

  updateParams(param) {
    this.setState({
      param: param
    });
  }

  componentDidUpdate() {
    const params = window.location.pathname.toString().split('/');
    if (this.state?.param !== params[1]) {
      this.setState({
        param: params[1]
      });
      this.requestProducts();
    }
  }

  componentDidMount() {
    const params = window.location.pathname.toString().split('/');
    this.setState({
      param: params[1]
    });
    this.requestProducts();

    let interval = setInterval(() => {
      this.requestProducts();
    }, 5000);
    this.setState({ interval: interval });
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    const { products } = this.state;
    const renderLoader = () => <p>Loading</p>;
    //moment().locale('et');

    return (
      <Suspense fallback={renderLoader()}>
        <Page title="StockNotificate.com - Stock checker">
          <Container maxWidth={false}>
            <Box mt={3}>
              <Drops data={products} label="Last drops" />
            </Box>
          </Container>
        </Page>
      </Suspense>
    );
  }
}

export default DropsView;
