import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';
import { BarChart as BarChartIcon } from 'react-feather';
import { Youtube as YouTubeIcon } from 'react-feather';
import { Activity as ActivityIcon } from 'react-feather';
import { Play as PlayIcon } from 'react-feather';
import { Monitor as MonitorIcon } from 'react-feather';
import { Hexagon as NvidiaIcon } from 'react-feather';
import { Image as AMDIcon } from 'react-feather';
import { Cpu as CPUIcon } from 'react-feather';
import { PlusCircle as PLUSIcon } from 'react-feather';
import NavItem from './NavItem';
import { Facebook, Twitter } from 'react-sharingbuttons';
import 'react-sharingbuttons/dist/main.css';
const items = [
  {
    href: '/gpu',
    icon: BarChartIcon,
    outside: false,
    title: 'GPU Hunter'
  },
  // {
  //   href: '/all',
  //   icon: ActivityIcon,
  //   outside: false,
  //   title: 'Stock listing'
  // },
  // {
  //   href: '/ps5',
  //   icon: PlayIcon,
  //   outside: false,
  //   title: 'Playstation 5'
  // },
  // {
  //   href: '/xbox',
  //   icon: MonitorIcon,
  //   outside: false,
  //   title: 'Xbox Series'
  // },
  // {
  //   href: '/amdprocessor',
  //   icon: CPUIcon,
  //   outside: false,
  //   title: 'AMD Processors'
  // },
  {
    href: '/gpu?brand=amd',
    icon: AMDIcon,
    outside: false,
    title: 'AMD Radeon'
  },
  {
    href: '/gpu?brand=nvidia',
    icon: NvidiaIcon,
    outside: false,
    title: 'Nvidia cards'
  },
  // {
  //   href: '/suggest',
  //   icon: PLUSIcon,
  //   outside: false,
  //   title: 'Suggest product'
  // },
  // {
  //   href: 'https://www.youtube.com/watch?v=ILLMS1T-dWg&ab_channel=Jaanus',
  //   icon: YouTubeIcon,
  //   outside: true,
  //   title: 'PS5 / XBOX live youtube'
  // },
  /*{
    href: 'https://youtu.be/Rb34Z9oihVI',
    icon: YouTubeIcon,
    outside: true,
    title: 'AMD Ryzen live youtube'
  }*/
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [models, setModels] = React.useState([]);

  // Pull /models from API
  const fetchModels = useCallback(async () => {
    let host = 'https://api.gpulook.com';
    if (process.env.NODE_ENV !== 'production') {
      host = 'http://localhost:8080';
    }
    const response = await fetch(`${host}/models`);
    const data = await response.json();
    setModels(data);
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    fetchModels();
  }, []);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              outside={item.outside}
              title={item.title}
              icon={item.icon}
            />
          ))}
          <Divider />
          {models.map((model) => (
            <NavItem
              href={`/gpu?model=${model.model}`}
              key={model.model}
              outside={false}
              title={model.model}
              icon={BarChartIcon}
            />
          ))}
        </List>
        {/* <Box mt={3}>
          <Button onClick={testSound}>Test notification sound</Button>
        </Box> */}
      </Box>
      {/* <Box p={2}>
        <Divider />
        <Facebook url="https://stocknotificate.com" />
        <Twitter
          url="https://stocknotificate.com"
          shareText="Checkout latest stock changes for AMD, Playstation5 and Nvidia"
        />
      </Box>
      <Box mt={3}>
        <div style={{ textAlign: 'center' }}>
          <h3 style={{ fontFamily: 'Roboto' }}>
            Join our discord for notifications
          </h3>
          <a href="https://discord.gg/MzUrSBef" target={'blank'}>
            <img
              style={{ width: '100px' }}
              alt="Discord"
              src="static/discord.svg"
            />
          </a>
        </div>
      </Box> */}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
