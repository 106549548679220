import React, { lazy, Suspense } from 'react';
import { Box, Container } from '@material-ui/core';
import fetchWithTimeout from './../../utils/Fetch';
const Page = lazy(() => import('./../../components/Page'));
const Console = lazy(() => import('./Console'));

class ConsoleView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
      data: [],
      interval: 0,
      param: ''
    };
  }

  getProduct(id) {
    for (var i = 0; i < this.state?.products.length; i++) {
      let product = this.state?.products[i];
      if (product.id === id) {
        return product;
      }
    }
  }

  requestProducts = () => {
    var host = 'https://api.stocknotificate.com:8000/latest';
    if (process.env.NODE_ENV !== 'production') {
      host = 'https://localhost:8000/latest';
    }
    fetchWithTimeout(host, {
      timeout: 3000,
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    })
      .then(res => res.json())
      .then(
        result => {
          // Before update check stock change
          if (this.state?.products.length > 0) {
            for (var i = 0; i < result.length; i++) {
              var product = this.getProduct(result[i].id);
              if (typeof product !== 'undefined') {
                if (!product.in_stock && result[i].in_stock) {
                  var audio = new Audio('/static/bell.mp3');
                  audio.play();
                }
              }
            }
          }

          // Update listing
          if (this.state?.param === '' || this.state?.param === 'all') {
            this.setState({
              products: result
            });
          } else {
            this.setState({
              products: result.filter(
                row => row.product_type === this.state?.param
              )
            });
          }
        },
        error => {
          this.setState({
            error
          });
        }
      );
  };

  updateParams(param) {
    this.setState({
      param: param
    });
  }

  componentDidUpdate() {
    const params = window.location.pathname.toString().split('/');
    if (this.state?.param !== params[1]) {
      this.setState({
        param: params[1]
      });
      this.requestProducts();
    }
  }

  componentDidMount() {
    const params = window.location.pathname.toString().split('/');
    this.setState({
      param: params[1]
    });
    this.requestProducts();

    let interval = setInterval(() => {
      this.requestProducts();
    }, 5000);
    this.setState({ interval: interval });
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    const { products } = this.state;
    const renderLoader = () => <p>Loading</p>;
    //moment().locale('et');

    return (
      <Suspense fallback={renderLoader()}>
        <Page title="StockNotificate.com - Stock checker">
          <Container maxWidth={false}>
            <Box mt={3}>
              <h1 style={{ fontFamily: 'Roboto' }}>
                StockNotificate.com - Stock notification with audio effects
              </h1>
              <h2 style={{ fontFamily: 'Roboto' }}>
                Check stock for Playstation 5, Xbox Series, AMD Radeon 6800, 6800 XT, AMD
                5950X, AMD 5900X, Nvidia RTX 3080, Nvidia RTX3090 and more..
              </h2>
            </Box>
            <Box mt={3}>
              <Console data={products} label="Latest stock checkings..." />
            </Box>

            <Box style={{ fontFamily: 'Roboto' }} mt={3}>
              <h5>
                Stocknotificate.com is a participant in the Amazon Services LLC
                Associates Program, an affiliate advertising program designed to
                provide a means for sites to earn advertising fees by
                advertising and linking to Amazon.com.
              </h5>
            </Box>
          </Container>
        </Page>
      </Suspense>
    );
  }
}

export default ConsoleView;
