import React, { lazy, Suspense, useCallback } from 'react';
import { Box, Container, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import fetchWithTimeout from '../../utils/Fetch';

const StockList = lazy(() => import('./StockList'));
const Page = lazy(() => import('../../components/Page'));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function StockView() {
  const [products, setProducts] = React.useState([]);
  const [brand, setBrand] = React.useState('');
  const [model, setModel] = React.useState('');
  const query = useQuery();

  React.useEffect(() => {
    const urlBrand = query.get('brand');
    const urlModel = query.get('model');
    setBrand(urlBrand);
    setModel(urlModel);
  }, [query]);

  const requestProducts = useCallback(() => {
    let host = 'https://api.gpulook.com';
    if (process.env.NODE_ENV !== 'production') {
      host = 'http://localhost:8080';
    }
    fetchWithTimeout(host, {
      timeout: 3000,
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // Sort result by price
          result.sort((a, b) => b.price - a.price);

          // Update list
          setProducts(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        () => {
          console.log('Error fetching products');
        }
      );
  }, []);

  React.useEffect(() => {
    requestProducts();
    const intervalRef = setInterval(() => {
      requestProducts();
    }, 30000);
    return () => clearInterval(intervalRef);
  }, [requestProducts]);

  const renderLoader = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <img
        alt="Loading"
        src="static/loading.gif"
        style={{ width: '100px', height: '100px' }}
      />
    </Box>
  );
  return (
    <Suspense fallback={renderLoader()}>
      <Page title="GPULook.com - GPU spy">
        <Container maxWidth={false}>
          <Box mt={3}>
            <h1 style={{ fontFamily: 'Roboto' }}>
              GPULook.com - GPU spy
            </h1>
            <h2 style={{ fontFamily: 'Roboto' }}>
              Find the latest prices for AMD and Nvidia videocards
            </h2>
          </Box>
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item>
                {model !== null ? (
                  <StockList
                    label={model}
                    data={products.filter((product) => product.model === model)}
                  />
                ) : (
                  <>
                    {(brand === null || brand === 'amd') && (
                      <>
                        <StockList
                          label="AMD"
                          data={products.filter((product) => product.brand === 'AMD')}
                        />
                        <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
                      </>
                    )}
                    {(brand === null || brand === 'nvidia') && (
                      <StockList
                        label="Nvidia"
                        data={products.filter((product) => product.brand === 'NVIDIA')}
                      />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box style={{ fontFamily: 'Roboto' }} mt={3}>
            <h5>
              GPUHunt.com is a participant in the Amazon Services LLC
              Associates Program, an affiliate advertising program designed to
              provide a means for sites to earn advertising fees by
              advertising and linking to Amazon.com.
            </h5>
          </Box>
        </Container>
      </Page>
    </Suspense>
  );
}

export default StockView;
